import { useEffect } from "preact/hooks"

import Router from "preact-router"
import { Menu } from "./components/Menu"
import { Steps } from "./components/Steps"
import { StartScreen } from "./components/StartScreen"

export const App = () => {
  const language = document.documentElement.lang

  useEffect(() => {
    document.body.classList.add("suppress-f3cc-modify")
  }, [])

  return (
    <div>
      <div class="main-container">
        <Router>
          <StartScreen path={`/${language}/`} />
          <Steps path={`/${language}/:step/`} />
        </Router>
      </div>
      <Menu />
    </div>
  )
}
