import { useCallback, useEffect, useRef, useState } from "preact/hooks"

export function useLocation() {
  const watchId = useRef()

  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [error, setError] = useState()
  const [isWatching, setIsWatching] = useState(false)

  const stop = useCallback(() => {
    navigator.geolocation.clearWatch(watchId.current)
    watchId.current = null
    setIsWatching(false)
  }, [])

  const start = useCallback(() => {
    stop()

    watchId.current = navigator.geolocation.watchPosition(
      (pos) => {
        console.log("geolocation.watchPosition update", pos)
        setLatitude(pos.coords.latitude)
        setLongitude(pos.coords.longitude)
        setError(null)
      },
      (err) => {
        console.error("geolocation.watchPosition error", err)
        setError(err)
      },
      {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000,
      },
    )
    setIsWatching(true)
  }, [stop])

  useEffect(() => {
    return stop
  }, [stop])

  return {
    latitude,
    longitude,
    error,
    start,
    stop,
    isWatching,
  }
}
