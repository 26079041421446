import { useMemo } from "preact/hooks"

export function onReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export function useStaticJSON(id) {
  return useMemo(() => {
    try {
      const el = document.getElementById(id)
      return JSON.parse(el.textContent) || {}
    } catch (err) {
      return {}
    }
  }, [id])
}

export function useGlobalData() {
  return useStaticJSON("app_json")
}
