import { useEffect, useRef, useState } from "preact/hooks"

import { Wrapper } from "@googlemaps/react-wrapper"

// import { Button } from "components/Button/Button"
import { useLocation } from "useLocation.js"

import locationMarker from "assets/location_marker.svg"
import positionMarker from "assets/position_marker.svg"

export const Map = (props) => (
  <Wrapper apiKey="AIzaSyAWGBT87Tj3613ou5rVrxHKvBsRPBDPJfM">
    <MapImpl {...props} />
  </Wrapper>
)

const MapImpl = ({ step, buttons }) => {
  const [isAudioMuted, setIsAudioMuted] = useState(false)
  const [audio, setAudio] = useState(null)

  const ref = useRef(null)
  const [map, setMap] = useState(null)

  const userLocation = useLocation()

  useEffect(() => {
    if (step.audio) {
      setAudio(new Audio(step.audio))
    }
  }, [step])

  useEffect(() => {
    if (audio) {
      audio.play()
    }
  }, [audio])

  useEffect(() => {
    if (audio) {
      audio.muted = isAudioMuted
    }
    return () => {
      if (audio) {
        audio.currentTime = audio.duration
        audio.pause()
      }
    }
  }, [isAudioMuted, audio])

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          mapId: "34898ac9d061343",
          zoom: 15,
          center: {
            lat: step.coordinates[0],
            lng: step.coordinates[1],
          },
          disableDefaultUI: true,
        }),
      )
    }
  }, [ref, map, step])

  useEffect(() => {
    if (userLocation.latitude && userLocation.longitude) {
      const bounds = new window.google.maps.LatLngBounds()
      bounds.extend(
        new window.google.maps.LatLng({
          lat: step.coordinates[0],
          lng: step.coordinates[1],
        }),
      )
      bounds.extend(
        new window.google.maps.LatLng({
          lat: userLocation.latitude,
          lng: userLocation.longitude,
        }),
      )
      map.fitBounds(bounds)
      map.panToBounds(bounds)
    }
  }, [map, step, userLocation])

  return (
    <div class="steps_container__step">
      <div ref={ref} class="map">
        <button
          onClick={() =>
            userLocation.isWatching ? userLocation.stop() : userLocation.start()
          }
          class={[
            "watch",
            userLocation.isWatching ? "watch--watching" : "",
            userLocation.error ? "watch--error" : "",
          ].join(" ")}
        />
        {userLocation.latitude && userLocation.longitude ? (
          <Marker
            map={map}
            position={{
              lat: userLocation.latitude,
              lng: userLocation.longitude,
            }}
            icon={positionMarker}
          />
        ) : null}
        <Marker
          map={map}
          position={{
            lat: step.coordinates[0],
            lng: step.coordinates[1],
          }}
          icon={{ url: locationMarker, labelOrigin: { x: 40, y: 19 } }}
          // Some stuff has to be set here and other stuff via CSS
          // Refer to here:
          // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
          label={{
            text: step.label || "<label missing>",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "monarcha",
            color: "var(--violet)",
            className: "marker-label",
          }}
        />
      </div>
      {audio ? (
        <button
          onClick={() => setIsAudioMuted(!isAudioMuted)}
          class={["mute-button", isAudioMuted ? "mute-button--muted" : ""].join(
            " ",
          )}
        />
      ) : null}
      <div dangerouslySetInnerHTML={{ __html: step.content }} />
      {buttons}
    </div>
  )
}

const Marker = (options) => {
  const [marker, setMarker] = useState(null)

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker())
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  return null
}
