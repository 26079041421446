import { useState } from "preact/hooks"
import shuffle from "@anttikissa/fisher-yates-shuffle"

import { Button } from "components/Button"

const raf = window.requestAnimationFrame
const animateElement = (element, cls) => {
  raf(() => {
    element.classList.remove(cls)
    raf(() => {
      element.classList.add(cls)
    })
  })
}

const Answer = ({ answer, className, setChosenAnswer }) => {
  return (
    <Button
      type="dark"
      onClick={() => {
        setChosenAnswer(answer)
        if (!answer.isCorrect) animateElement(document.body, "screen-shake")
      }}
      class={className}
      text={answer.text}
    />
  )
}

export const Quiz = ({ step, buttons }) => {
  const [chosenAnswer, setChosenAnswer] = useState(null)

  const [answers] = useState(() => shuffle(step.answers))

  return (
    <div class="steps_container__step">
      <h2>{step.question}</h2>
      <div class="answers-container">
        {answers.map((answer) => (
          <Answer
            key={answer.id}
            answer={answer}
            setChosenAnswer={setChosenAnswer}
            className={[
              "button",
              "answer",
              chosenAnswer === answer && answer.isCorrect
                ? "answer--correct"
                : null,
              chosenAnswer === answer && !answer.isCorrect
                ? "answer--wrong"
                : null,
            ].join(" ")}
          />
        ))}
      </div>
      {buttons}
    </div>
  )
}
