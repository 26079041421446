export const menuOpen = (
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6.11318C2 6.11318 6.68276 -0.547013 17.8673 4.05659C29.0518 8.66019 33.7346 2 33.7346 2"
      stroke="currentColor"
      stroke-width="4"
    />
    <path
      d="M2 14.4667C2 14.4667 6.68276 7.8065 17.8673 12.4101C29.0518 17.0137 33.7346 10.3535 33.7346 10.3535"
      stroke="currentColor"
      stroke-width="4"
    />
    <path
      d="M2 22.8202C2 22.8202 6.68276 16.16 17.8673 20.7636C29.0518 25.3672 33.7346 18.707 33.7346 18.707"
      stroke="currentColor"
      stroke-width="4"
    />
  </svg>
)
export const menuClose = (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999989 2.90846C0.999989 2.90846 9.02067 1.5102 13.6741 12.6741C18.3275 23.838 26.3482 22.4397 26.3482 22.4397"
      stroke="currentColor"
      stroke-width="4"
    />
    <path
      d="M26.3482 2.90846C26.3482 2.90846 18.3275 1.5102 13.6741 12.6741C9.02066 23.838 0.99998 22.4397 0.99998 22.4397"
      stroke="currentColor"
      stroke-width="4"
    />
  </svg>
)

export const correct = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="white" />
    <path
      d="M12 23.13L4.5 15.63L6.615 13.515L12 18.885L23.385 7.5L25.5 9.63L12 23.13Z"
      fill="#D7E071"
    />
  </svg>
)

export const incorrect = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="white" />
    <path
      d="M22.5 20.385L20.385 22.5L15 17.115L9.615 22.5L7.5 20.385L12.885 15L7.5 9.615L9.615 7.5L15 12.885L20.385 7.5L22.5 9.615L17.115 15L22.5 20.385Z"
      fill="#C61168"
    />
  </svg>
)

export const phone = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.02 24.76C31.56 24.76 29.18 24.36 26.96 23.64C26.26 23.4 25.48 23.58 24.94 24.12L21.8 28.06C16.14 25.36 10.84 20.26 8.02 14.4L11.92 11.08C12.46 10.52 12.62 9.74 12.4 9.04C11.66 6.82 11.28 4.44 11.28 1.98C11.28 0.9 10.38 0 9.3 0H2.38C1.3 0 0 0.48 0 1.98C0 20.56 15.46 36 34.02 36C35.44 36 36 34.74 36 33.64V26.74C36 25.66 35.1 24.76 34.02 24.76Z"
      fill="#552CC8"
    />
  </svg>
)
