import "./reset.css"
import "./colors.css"
import "./global.css"
import "./button.css"
import "./menu.css"
import "./overlay.css"
import "./steps.css"
import "./audiowithimages.css"
import "./quiz.css"
import "./map.css"
import "./code.css"

import "./storydetail.css"
import "./startscreen.css"

import { render } from "preact"
import { App } from "./App"
import { Menu } from "./components/Menu"
import { onReady } from "./utils"

import "./hideURL"

onReady(() => {
  const el = document.querySelector("main[data-app]")
  const menu = document.querySelector("[data-menu]")

  if (el) {
    // The app gets rendered, from here on all the routing is done with the Router config in App.js
    render(<App />, el)
  }
  if (menu) {
    // For static, CMS generated sites (e.g. Imprint, Further Links) we just render the menu component
    render(<Menu />, menu)
  }
})

// Load Web Fonts
const WebFont = require("webfontloader")
WebFont.load({
  typekit: {
    id: "ohk6wdw", // Adobe Typekit fonts using the project id
  },
})
