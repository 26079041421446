/* global gettext */

import { useLayoutEffect } from "preact/hooks"

import { Button } from "components/Button"

export const StartScreen = () => {
  const language = document.documentElement.lang

  useLayoutEffect(() => {
    const c = document.body.classList
    c.add("start")
    return () => c.remove("start")
  }, [])

  return (
    <>
      <div class="title-bg">
        <div style="background-image:url(/static/assets/keyvisual.gif)" />
      </div>
      <div class="title title--start">
        <h1 class="title__main">{gettext("Source code")}</h1>
        <h2 class="title__sub">
          {gettext(
            "An interactive time-travelling journey through the baths of Baden",
          )}
        </h2>
      </div>
      <div class="buttons">
        <Button
          class="ml"
          text={gettext("next")}
          type={"dark"}
          href={`/${language}/0/`}
        />
      </div>
    </>
  )
}
