/* global gettext */
import { useEffect, useState } from "preact/hooks"

import { Overlay } from "./Overlay"
import { ring } from "../ringtone.js"
import * as icons from "./icons"

export const AudioWithImages = ({ step, previousButton, nextButton }) => {
  const [yaraRinging, setYaraRinging] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [audio, setAudio] = useState(null)
  const [time, setTime] = useState(0)
  const [hasPlayed, setHasPlayed] = useState(false)
  // const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    if (audio) {
      audio.ontimeupdate = () => {
        setTime(audio.currentTime)
      }
      audio.onended = () => {
        setHasPlayed(true)
        setIsPlaying(false)
      }
    }
    return () => {
      if (audio) {
        audio.currentTime = audio.duration
        audio.pause()
      }
    }
  }, [audio])

  useEffect(() => {
    return () => {
      ring(false)
    }
  }, [])

  useEffect(() => {
    step && setAudio(new Audio(step.audio_file))
  }, [step])

  const togglePlay = () => {
    if (isPlaying) {
      setIsPlaying(false)
      audio.pause()
    } else {
      setIsPlaying(true)
      if (hasPlayed) {
        setHasPlayed(false)
      }
      audio.play()
    }
  }

  const rewind = async () => {
    audio.currentTime -= 5
  }

  const fwd = async () => {
    audio.currentTime += 5
  }

  const yaraOnThePhone = step.is_yara ? (
    <div class="yara">
      <h1>Yara</h1>
      <p>{gettext("on the phone")}</p>
      <img src={require("../assets/yara.png")} alt="Yara" />
    </div>
  ) : null

  const image =
    step.images &&
    step.images.find((image) => image.start <= time && image.end >= time)

  const callEnded =
    step.is_yara && time >= step.call_ended ? (
      <div class="yara yara--ended">
        <h1>{gettext("Call ended")}</h1>
        <div class="phone-button">{icons.phone}</div>
      </div>
    ) : null

  return (
    <div class="steps_container__step">
      {step.is_yara && yaraRinging ? (
        <div class="yara-overlay">
          <Overlay>
            <div class="yara">
              <h1>Yara</h1>
              <p>{gettext("is calling")}</p>
              <img src={require("../assets/yara.png")} alt="Yara" />
              <button
                class="phone-button"
                onClick={() => {
                  togglePlay()
                  setYaraRinging(false)
                  ring(false)
                }}
              >
                {icons.phone}
              </button>
            </div>
          </Overlay>
        </div>
      ) : null}
      {callEnded ? (
        callEnded
      ) : image ? (
        <img class="audio-image" src={image.file} />
      ) : (
        yaraOnThePhone
      )}
      <div class="buttons buttons--shadow">
        <div class="timeline">
          <div
            class="timeline-progress"
            style={{ width: `${(time / audio?.duration) * 100}%` }}
          />
        </div>
        {previousButton}
        <div class="audio-buttons">
          <button onClick={() => rewind()} class="rewind-button" />
          <button
            onClick={() => togglePlay()}
            class={[
              "pause-play-button",
              isPlaying ? "pause-play-button--playing" : "",
            ].join(" ")}
          />
          <button onClick={() => fwd()} class="forward-button" />
        </div>
        {nextButton}
      </div>
    </div>
  )
}
