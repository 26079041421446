/* global gettext */
import { route } from "preact-router"

import { AudioWithImages } from "./AudioWithImages"
import { Quiz } from "./Quiz"
import { Code } from "./Code"
import { Button } from "./Button"
import { Map } from "./Map"
import { ring } from "../ringtone.js"
import { useGlobalData } from "utils"

const Fallback = ({ step, buttons }) => (
  <div class="steps_container__step">
    <div dangerouslySetInnerHTML={{ __html: step.content }} />
    {buttons}
  </div>
)

const TYPES = {
  audio: AudioWithImages,
  quiz: Quiz,
  code: Code,
  locationmap: Map,
}

function Step(props) {
  const step = props.step
  if (!props.step) return null
  // console.log(JSON.stringify(step))
  const Component = TYPES[step.type] || Fallback
  return <Component {...props} />
}

export function Steps({ matches }) {
  const { steps, end_url } = useGlobalData()
  const currentIndex = parseInt(matches.step, 10)
  const language = document.documentElement.lang

  const next = () => {
    let nextStep = steps[currentIndex + 1]
    if (nextStep?.is_yara) ring(true)
    route(`/${language}/${currentIndex + 1}/`)
    window.scrollTo(0, 1)
  }
  const previous = () => {
    let previousStep = steps[currentIndex - 1]
    if (previousStep?.is_yara) ring(true)
    route(`/${language}/${currentIndex - 1}/`)
    window.scrollTo(0, 1)
  }

  const previousButton =
    currentIndex > 0 ? (
      <Button text={gettext("back")} onClick={previous} />
    ) : null
  const nextButton =
    currentIndex < steps.length - 1 ? (
      <Button
        class="ml"
        text={currentIndex ? gettext("next") : gettext("start now")}
        onClick={next}
      />
    ) : (
      <Button
        class="ml"
        text={gettext("next")}
        onClick={() => {
          window.location.href = end_url
        }}
      />
    )

  if (!steps.length) {
    window.location.href = end_url
    return null
  }

  const step = steps[currentIndex]
  return step ? (
    <div class="steps-container">
      <Step
        step={step}
        next={next}
        previous={previous}
        buttons={
          <div class="buttons">
            {previousButton}
            {nextButton}
          </div>
        }
        previousButton={previousButton}
        nextButton={nextButton}
        key={currentIndex}
      />
    </div>
  ) : null
}
