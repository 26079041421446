export const Button = ({
  href,
  type,
  onClick,
  text,
  disabled,
  blank = false,
  theme = "dark",
  ...props
}) => {
  let styles = ["button"]
  if (props["class"]) {
    styles = [props["class"], styles]
  }
  if (disabled) {
    styles.push("button--disabled")
  }
  styles.push(
    type ? `button--${type}` : `button--${theme == "dark" ? "light" : "dark"}`,
  )
  const attrs = blank ? { target: "_blank", rel: "noopener" } : null
  return href ? (
    <a href={href} class={styles.join(" ")} {...attrs}>
      {text}
    </a>
  ) : (
    <button disabled={disabled} onClick={onClick} class={styles.join(" ")}>
      {text}
    </button>
  )
}
