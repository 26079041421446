/* global gettext */
import { useState } from "preact/hooks"
import { Button } from "./Button"
import { Overlay } from "./Overlay"

export const CancelDialog = ({ yesCallBack, noCallback }) => {
  const [isShowing, setIsShowing] = useState(true)
  return isShowing ? (
    <Overlay>
      <h1>
        {gettext(
          "Do you really want to go back and restart from the beginning?",
        )}
      </h1>
      <div class="buttons">
        <Button
          text={gettext("Yes")}
          onClick={() => {
            setIsShowing(false)
            yesCallBack()
          }}
        />
        <Button
          text={gettext("No")}
          onClick={() => {
            setIsShowing(false)
            noCallback()
          }}
        />
      </div>
    </Overlay>
  ) : null
}
