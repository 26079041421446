/* global gettext */
import { useEffect, useRef, useState } from "preact/hooks"

import { Button } from "components/Button"
import * as icons from "./icons"

const raf = window.requestAnimationFrame
const animateElement = (element, cls) => {
  raf(() => {
    element.classList.remove(cls)
    raf(() => {
      element.classList.add(cls)
    })
  })
}

const emptyStrings = (length) => [...Array(length).fill("")]

const STATE_INPUT = 1
const STATE_CORRECT = 2
const STATE_INCORRECT = 3

export const Code = ({ step, next, previousButton, nextButton }) => {
  const inputRef = useRef()
  const resolutionRef = useRef()

  const [input, setInput] = useState({ value: "" })
  const [showHint, setShowHint] = useState(false)

  const [code, setCode] = useState(() => emptyStrings(step.code.length))
  const [position, setPosition] = useState(0)

  const [state, setState] = useState(STATE_INPUT)

  useEffect(() => {
    const cl = document.body.classList
    cl.toggle("code-step", !showHint)
    return () => {
      cl.remove("code-step")
    }
  }, [showHint])

  useEffect(() => {
    const joined = code.join("")
    if (joined == step.code) {
      setState(STATE_CORRECT)
    } else if (joined.length === step.code.length) {
      animateElement(document.body, "screen-shake")
      setState(STATE_INCORRECT)
      setPosition(step.code.length)

      setTimeout(() => {
        setCode(emptyStrings(step.code.length))
        setPosition(0)
        setState(STATE_INPUT)
        inputRef.current.focus()
      }, 1500)
    }
  })

  return (
    <div
      class="steps_container__step"
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }}
    >
      {showHint ? (
        <div>
          <h2>{gettext("Hint")}</h2>
          <div
            class="richtext"
            dangerouslySetInnerHTML={{ __html: step.hint }}
          />
          <div class="richtext hide" ref={resolutionRef}>
            <p>
              <br />
              {gettext("The code is:")} {step.code}
            </p>
          </div>
          <div class="buttons">
            <Button
              type="light"
              onClick={() => {
                setShowHint(false)
                setTimeout(() => inputRef.current.focus(), 50)
              }}
              text={gettext("back")}
            />
            <Button
              type="light"
              onClick={(e) => {
                resolutionRef.current.classList.remove("hide")
                e.target.classList.add("hide")
              }}
              text={gettext("resolve puzzle")}
            />
          </div>
        </div>
      ) : (
        <div>
          <h2>
            {state === STATE_CORRECT
              ? gettext("Correct!")
              : gettext("Please enter the code.")}
          </h2>
          {step.task && state !== STATE_CORRECT ? (
            <div
              class="richtext"
              dangerouslySetInnerHTML={{ __html: step.task }}
            />
          ) : null}
          <div
            class={[
              "input-wrapper",
              state === STATE_CORRECT ? "input-wrapper--correct" : null,
              state === STATE_INCORRECT ? "input-wrapper--incorrect" : null,
            ]
              .filter(Boolean)
              .join(" ")}
            data-cells={step.code.length}
            style={{ "--cells": step.code.length }}
          >
            <input
              ref={inputRef}
              autofocus
              class="input"
              type="text"
              pattern="\d*"
              value={input.value}
              onInput={(e) => {
                setInput({ value: "" })

                if (position >= step.code.length) {
                  return
                }

                setCode((code) => {
                  const newCode = [...code]
                  newCode[position] = e.target.value
                  return newCode
                })
                setPosition((p) => p + 1)
              }}
            />
            {code.map((cell, idx) => (
              <span
                class={["cell", position == idx ? "cell--focus" : ""].join(" ")}
                key={idx}
                onClick={() => {
                  inputRef.current.focus()
                  // Allow focussing the first (!idx) and any other if the
                  // previous cell is filled in.
                  setPosition((p) => (!idx || (idx && code[idx - 1]) ? idx : p))
                }}
              >
                {cell}
              </span>
            ))}
          </div>
          <div class="state-icon">
            {state === STATE_CORRECT ? (
              <button onClick={next}>{icons.correct}</button>
            ) : null}
            {state === STATE_INCORRECT ? icons.incorrect : null}
          </div>
          {state === STATE_CORRECT ? (
            <div class="buttons">{nextButton}</div>
          ) : (
            <div class="buttons">
              {previousButton}
              <Button
                class="ml"
                type="light"
                onClick={() => setShowHint(true)}
                text={gettext("Hint")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
