import ringtone from "./assets/ringtone.mp3"

const audio = new Audio(ringtone)
audio.loop = true

export function ring(play) {
  if (play) {
    audio.play()
  } else {
    audio.pause()
    audio.currentTime = 0
  }
}
