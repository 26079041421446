/* global gettext */
import { Fragment } from "preact"
import { useLayoutEffect, useState } from "preact/hooks"

import { Overlay } from "components/Overlay"
import { Button } from "components/Button"
import { CancelDialog } from "components/CancelDialog"

import * as icons from "./icons"

import { useStaticJSON } from "utils"

export const Menu = () => {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const language = document.documentElement.lang

  const menus = useStaticJSON("menus_json", {})
  const mainMenu = menus.main || []

  useLayoutEffect(() => {
    const c = document.body.classList
    c.toggle("menu", isMenuOpen)
  }, [isMenuOpen])

  return (
    <>
      <div class="menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? icons.menuClose : icons.menuOpen}
      </div>
      {isMenuOpen ? (
        <>
          <Overlay>
            <div class="menu">
              <h1 class="menu-title">{gettext("Menu")}</h1>
              <nav class="nav" role="navigation">
                {mainMenu.map((e, idx) => (
                  <Fragment key={idx}>
                    <Button href={e[1]} text={e[0]} blank />
                    <br />
                  </Fragment>
                ))}
                <Button
                  text={gettext("Start anew")}
                  onClick={() => setIsCancelDialogOpen(true)}
                />
              </nav>
              <nav class="language-nav" role="navigation">
                {language === "de" ? null : (
                  <Button href="/de/" type="white" text={"Deutsch"} />
                )}
                {language === "en" ? null : (
                  <Button href="/en/" type="white" text={"English"} />
                )}
              </nav>
            </div>
          </Overlay>
          {isCancelDialogOpen ? (
            <CancelDialog
              noCallback={() => setIsCancelDialogOpen(false)}
              yesCallBack={() => {
                setIsMenuOpen(false)
                setIsCancelDialogOpen(false)
                window.location.href = `/${language}/`
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  )
}
