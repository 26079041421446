// hide url bar

;(function () {
  let win = window,
    doc = win.document

  // If there's a hash, or addEventListener is undefined, stop here
  if (!location.hash || !win.addEventListener) {
    //scroll to 1
    window.scrollTo(0, 1)
    var scrollTop = 1,
      //reset to 0 on bodyready, if needed
      bodycheck = setInterval(() => {
        if (doc.body) {
          clearInterval(bodycheck)
          scrollTop = "scrollTop" in doc.body ? doc.body.scrollTop : 1
          win.scrollTo(0, scrollTop === 1 ? 0 : 1)
        }
      }, 15)

    if (win.addEventListener) {
      win.addEventListener(
        "load",
        () => {
          setTimeout(() => {
            //reset to hide addr bar at onload
            win.scrollTo(0, scrollTop === 1 ? 0 : 1)
          }, 0)
        },
        false,
      )
    }
  }
})()
